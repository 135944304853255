<template>
  <DefaultTemplate>
    <div class="box-card" id="user-personal" v-if="loading">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="set-breadcrumb"
          >
            <el-breadcrumb-item :to="{ path: '/user/detail/' + criterionId }"
              >จัดการข้อมูลผู้ประกอบการ</el-breadcrumb-item
            >
            <el-breadcrumb-item>ข้อมูลส่วนตัว</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="24" class="mg-b-4">
          <p class="text-center font-30 font-weight-500">ข้อมูลส่วนตัว</p>
        </el-col>
        <div class="is-flex js-center w-100 flex-column">
          <el-col
            :span="24"
            :sm="16"
            :md="20"
            :lg="16"
            class="text-center mg-x-auto"
          >
            <img src="@/assets/user.png" alt="" class="img-user" />
          </el-col>
          <el-col :span="24" :sm="16" :md="20" :lg="16" class="mg-x-auto">
            <el-form label-position="top" label-width="100px" :model="dataUser">
              <el-form-item label="คำนำหน้าชื่อ">
                <el-select
                  v-model="dataUser.title"
                  :disabled="true"
                  class="w-100"
                >
                  <el-option
                    v-for="item in optionTitle"
                    :key="item.value"
                    :label="item.label"
                    :value="`${item.value}`"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="ชื่อ">
                <el-input v-model="dataUser.name" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="นามสกุล">
                <el-input
                  v-model="dataUser.surname"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="เพศ">
                <el-radio-group v-model="dataUser.gender" :disabled="true">
                  <el-radio :label="1">ชาย</el-radio>
                  <el-radio :label="2">หญิง</el-radio>
                </el-radio-group>
              </el-form-item> -->
              <!-- <el-form-item label="อายุ">
                <el-input v-model="dataUser.age" :disabled="true"></el-input>
              </el-form-item> -->
              <!-- <el-form-item label="วุฒิการศึกษา">
                <el-select
                  v-model="dataUser.education"
                  :disabled="true"
                  class="w-100"
                >
                  <el-option
                    v-for="item in optionEducation"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="อีเมล">
                <el-input v-model="dataUser.email" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="เบอร์โทรศัพท์มือถือ">
                <el-input
                  v-model="dataUser.mobilephone"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </div>
      </el-row>
    </div>
    <DefaultError v-if="noDetailId">
      <div class="main-slot text-primary pd-5">
        <h3 class="text-center text-primary">
          EXAC เติมเต็มความเป็นเลิศส่งออกไทย <br />เติบโตไกลอย่างยั่งยืน
        </h3>
        <div class="text-center text-grey text-primary mg-t-1 font-20">
          ขออภัย ไม่พบข้อมูลดังกล่าว<br />
        </div>
      </div>
    </DefaultError>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import DefaultError from "@/template/DefaultError";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
    DefaultError,
  },
  data() {
    return {
      loading: false,
      noDetailId: false,
      dataUser: [],
      criterionId: this.$route.params.criterionId
    };
  },
  mounted() {
    this.getUserById();
  },
  computed: {
    ...mapState({
      pageActive: (state) => state.pageActive,
      _token: (state) => state._token,
      optionTitle: (state) => state.userOption.title,
      optionEducation: (state) => state.userOption.education,
    }),
  },
  methods: {
    getUserById() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`user/profile/${this.$route.params.id}`)
        .then((res) => {
          if (res.data.success) {
            if (res.data.obj != null) {
              this.dataUser = res.data.obj;
              this.loading = true;
            } else {
              this.$router.push("/" + this.pageActive);
              this.$message({
                message: "ไม่พบข้อมูลบริษัทนี้",
                type: "error",
                duration: 4000,
              });
            }
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          }
          if (e.response.data.status == 422) {
            this.noDetailId = true;
            this.$message({
              message: "ไม่พบข้อมูลส่วนตัว",
              type: "error",
              duration: 4000,
            });
            setTimeout(() => {
              window.close();
            }, 1200);
          } else {
            this.alertCatchError(e);
          }
        });
    },
  },
};
</script>
