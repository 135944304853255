<template>
  <div id="not-found-page" :class="page == 'not-allowed' ? 'not-allowed' : ''">

    <div class="main-slot text-primary pd-5">
      <img src="@/assets/logo-login.png" alt="EXAC logo" class="logo" />
      <h1 class="color-35">CFOE (Customer Filtering & Offering Engine)</h1>
      <h3 class="text-primary">
        EXAC เติมเต็มความเป็นเลิศส่งออกไทย<br />เติบโตไกลอย่างยั่งยืน
      </h3>
      <slot />
    </div>
    <div class="footer">
      <span
        >© สงวนลิขสิทธิ์ พ.ศ.
        {{ new Date().getFullYear() + 543 }}
        ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props:["page"]
};
</script>

<style scoped>
*,
*:before,
*:after {
  box-sizing: unset;
}
</style>
